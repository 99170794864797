import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/services/language/language.service';

@Component({
  selector: 'app-blaty-paleta-kolorow',
  templateUrl: './blaty-paleta-kolorow.component.html',
  styleUrls: ['./blaty-paleta-kolorow.component.scss']
})
export class BlatyPaletaKolorowComponent implements OnInit {
  
  private Language: any = this._languageService.GetLanguageConfiguration();

  public blaty_matrix = []

  constructor(private _languageService : LanguageService) { }

  async ngOnInit() {
    this.Language = await this._languageService.GetLanguageConfiguration();  
    this.blaty_matrix = [
      {'key': 'Z108', 'value': this.Language.blaty_kompaktowe_kolory_bialyglebokimat},
      {'key': 'Z106', 'value': this.Language.blaty_kompaktowe_kolory_szaryglebodkimat},
      {'key': 'Z104', 'value': this.Language.blaty_kompaktowe_kolory_oliwkowyglebokimat},
      {'key': 'Z105', 'value': this.Language.blaty_kompaktowe_kolory_czarnyglebokimat},
      {'key': '3096', 'value': this.Language.blaty_kompaktowe_kolory_bialy},
      {'key': '3096_dab', 'value': this.Language.blaty_kompaktowe_kolory_dabbialy},
      {'key': 'N002', 'value': this.Language.blaty_kompaktowe_kolory_jasnyszary},
      {'key': '3190', 'value': this.Language.blaty_kompaktowe_kolory_czarny},
      {'key': 'N004', 'value': this.Language.blaty_kompaktowe_kolory_ciemnyszary},
      {'key': 'N005', 'value': this.Language.blaty_kompaktowe_kolory_miedzianyszary},
      {'key': 'C001', 'value': this.Language.blaty_kompaktowe_kolory_dabszary},
      {'key': 'C003', 'value': this.Language.blaty_kompaktowe_kolory_dabnaturalny},
      {'key': 'OL00', 'value': this.Language.blaty_kompaktowe_kolory_marmur},
      {'key': 'R014', 'value': this.Language.blaty_kompaktowe_kolory_bezowy},
      {'key': 'R012', 'value': this.Language.blaty_kompaktowe_kolory_szary},
      {'key': 'OL03', 'value': this.Language.blaty_kompaktowe_kolory_agat},
      {'key': 'R016', 'value': this.Language.blaty_kompaktowe_kolory_brazowy},
      {'key': 'OL04', 'value': this.Language.blaty_kompaktowe_kolory_granit},
    ]
  }
}
