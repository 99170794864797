import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'app/services/global.service';
import {LanguageService} from '../../services/language/language.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  private Language: any = this._languageService.GetLanguageConfiguration();
  private data : Date = new Date();

  constructor(private _languageService: LanguageService,
    private _globalService: GlobalService) { }

  async ngOnInit() {
    this.Language = await this._languageService.GetLanguageConfiguration();
    console.log(`RDate: ${this._globalService.ReleaseDate}`);
  }
}
