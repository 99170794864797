import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { NouisliderModule } from 'ng2-nouislider';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { RouterModule } from '@angular/router';

import { BasicelementsComponent } from './basicelements/basicelements.component';
import { NavigationComponent } from './navigation/navigation.component';
import { TypographyComponent } from './typography/typography.component';
import { NucleoiconsComponent } from './nucleoicons/nucleoicons.component';
import { ComponentsComponent } from './components.component';
import { NotificationComponent } from './notification/notification.component';
import { NgbdModalBasic } from './modal/modal.component';
import { ContactComponent } from './contact/contact.component';
import { FrontyAkryloweComponent } from './fronty-akrylowe/fronty-akrylowe.component';
import { PolyskPaletaKolorowComponent } from './fronty-akrylowe/polysk-paleta-kolorow/polysk-paleta-kolorow.component';
import { MatPaletaKolorowComponent } from './fronty-akrylowe/mat-paleta-kolorow/mat-paleta-kolorow.component';
import { VelvetPaletaKolorowComponent } from './fronty-akrylowe/velvet-paleta-kolorow/velvet-paleta-kolorow.component';
import { NaszeRealizacjeComponent } from './nasze-realizacje/nasze-realizacje.component';
import { ModalsModule } from 'app/modals/modals.module';
import { BlatyKompaktoweComponent } from './blaty-kompaktowe/blaty-kompaktowe.component';
import { BlatyPaletaKolorowComponent } from './blaty-kompaktowe/blaty-paleta-kolorow/blaty-paleta-kolorow.component';
import { FrontyFrezowaneMdfComponent } from './fronty-frezowane-mdf/fronty-frezowane-mdf.component';
import { ONasComponent } from './o-nas/o-nas.component';
import { CiecieOkreinowanieComponent } from './ciecie-okreinowanie/ciecie-okreinowanie.component';
import { FrontyFornirowaneComponent } from './fronty-fornirowane/fronty-fornirowane.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        NouisliderModule,
        RouterModule,
        JwBootstrapSwitchNg2Module,
        ModalsModule
    ],
    declarations: [
        ComponentsComponent,
        BasicelementsComponent,
        NavigationComponent,
        TypographyComponent,
        NucleoiconsComponent,
        NotificationComponent,
        NgbdModalBasic,
        ContactComponent,
        FrontyAkryloweComponent,
        PolyskPaletaKolorowComponent,
        MatPaletaKolorowComponent,
        VelvetPaletaKolorowComponent,
        NaszeRealizacjeComponent,
        BlatyKompaktoweComponent,
        BlatyPaletaKolorowComponent,
        FrontyFrezowaneMdfComponent,
        ONasComponent,
        CiecieOkreinowanieComponent,
        FrontyFornirowaneComponent
    ],
    exports: [ComponentsComponent]
})
export class ComponentsModule { }
