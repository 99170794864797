import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullScreenImageDisplayComponent } from './full-screen-image-display/full-screen-image-display.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    FullScreenImageDisplayComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbModule,
  ]
})
export class ModalsModule { }
