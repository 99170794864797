import { Injectable } from '@angular/core';
import { Fra } from './FRA';
import { Pol } from './POL';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private _window: Window) { }

  public async GetLanguageFromStrorage(){
    let language = JSON.parse(localStorage.getItem('akryland-language'));
    if (language){
      return language.value;
    }

    let host = this._window.location.hostname;
    if (host.includes("akryland.fr")){
      return "FR"
    }    
    return "PL";
  }

  public async SetLanguageToStorage(language: string){
    localStorage.setItem('akryland-language', JSON.stringify({ value: language}));
  }

  public async GetLanguageConfiguration(){
    let lang = await this.GetLanguageFromStrorage();
    return this.GetConfiguration(lang);
  }

  public async SetLanguageConfiguration(language: string){
    await this.SetLanguageToStorage(language);
  }

  private GetConfiguration(lang: string){

    let conf = {
      "PL": Pol.Configuration,
      "FR": Fra.Configuration
    }
    return conf[lang]
  }
}
