import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/services/language/language.service';

@Component({
  selector: 'app-polysk-paleta-kolorow',
  templateUrl: './polysk-paleta-kolorow.component.html',
  styleUrls: ['./polysk-paleta-kolorow.component.scss']
})
export class PolyskPaletaKolorowComponent implements OnInit {

  private Language: any = this._languageService.GetLanguageConfiguration();

  public polysk_matrix = []

  constructor(private _languageService: LanguageService) { }

  async ngOnInit() {
    this.Language = await this._languageService.GetLanguageConfiguration();
    this.polysk_matrix = [
      { 'key': '1982', 'value': this.Language.fronty_akrylowe_polysk_kolor_bialy },
      { 'key': '11046', 'value': this.Language.fronty_akrylowe_polysk_kolor_bialy_zimny },
      { 'key': '7496', 'value': this.Language.fronty_akrylowe_polysk_kolor_bez },
      { 'key': '85384', 'value': this.Language.fronty_akrylowe_polysk_kolor_jasny_szary },
      { 'key': '85468', 'value': this.Language.fronty_akrylowe_polysk_kolor_jasny_cappucino },
      { 'key': '7498', 'value': this.Language.fronty_akrylowe_polysk_kolor_cappucino },
      { 'key': '5357', 'value': this.Language.fronty_akrylowe_polysk_kolor_golebi },
      { 'key': '4670', 'value': this.Language.fronty_akrylowe_polysk_kolor_jasnoniebieski },
      { 'key': '4644', 'value': this.Language.fronty_akrylowe_polysk_kolor_niebieski },
      { 'key': '85383', 'value': this.Language.fronty_akrylowe_polysk_kolor_szary },
      { 'key': '85382', 'value': this.Language.fronty_akrylowe_polysk_kolor_ciemnoszary },
      { 'key': '8421', 'value': this.Language.fronty_akrylowe_polysk_kolor_czarny },
      { 'key': '11036', 'value': this.Language.fronty_akrylowe_polysk_kolor_biala_perla_metalik },
      { 'key': '8636-85385', 'value': this.Language.fronty_akrylowe_polysk_kolor_szary_srebrny_metalik },
      { 'key': '7408-7499', 'value': this.Language.fronty_akrylowe_polysk_kolor_bez_metalik },
      { 'key': '4473-4562', 'value': this.Language.fronty_akrylowe_polysk_kolor_niebieski_metalik },
      { 'key': '8855-85387', 'value': this.Language.fronty_akrylowe_polysk_kolor_grafit_metalik },
      { 'key': '8378-8427', 'value': this.Language.fronty_akrylowe_polysk_kolor_czarny_metalik }
    ]
  }

}
