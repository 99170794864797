export class Fra {
    public static Configuration = {
      "navbar_footer_aboutus": "Entreprise",
      "navbar_footer_oursolutions": "Notre Projects",
      "navbar_footer_contaktnav": "Contact",
      "navbar_footer_produkcja_frontow": "Fabrication de Façades",
      "navbar_footer_fronty_akrylowe": "Façades Acryliques",
      "navbar_footer_fronty_frezowane": "Façades Fraisées MDF",
      "navbar_footer_fronty_fornirowane": "Façades Plaquées",
      "navbar_footer_uslugi_cnc": "Les Services CNC",
      "navbar_footer_uslugi_ciecieokleinowanie": "Coupe et Placage",
      "navbar_footer_uslugi_frezowanie": "Fraisage",
      "navbar_footer_blaty_kompaktowe": "Plan de Travail Compacts",
      // En passion pour les créations très bon designe
      "navbar_footer_motto1": "En passion pour les créations",
      "navbar_footer_motto2": "très bon",
      "navbar_footer_motto3": "designe",      
      "navbar_footer_zapraszamydokontaktu": "Zapraszamy do kontaktu:",
      "navbar_footer_jestesmynawhatsapp": "Nous sommes sur WhatsApp",
      "navbar_footer_przedstawicielhandlowy": "Przedstawiciel Handlowy:",
      "navbar_footer_jako": "Jako",
      "navbar_footer_jedyni": "jedyni",
      "navbar_footer_na_podkarpaciu": "na podkarpaciu wykonujemy fronty akrylowe w najnowocześniejszej technologii z użyciem",
      "navbar_footer_obrzeza_laserowego": "obrzeża laserowego.",
      "navbar_footer_onas_nadbanerem": 
        `jesteśmy nowoczesną, ciągle rozwijającą się firmą, zdobywającą doświadczenie na zachodnich rynkach. 
        Specjalizujemy się w produkcji frontów meblowych na wymiar. W naszej ofercie znajdą Państwo najwyższej jakości fronty akrylowe, 
        laminowane oraz MDF.`,
      "navbar_footer_ourportfolio": "Notre portfolio:",
      "navbar_footer_frontyakrylowe": "Façades Acryliques",
      "navbar_footer_frontyfrezowane": "Façades Fraisées MDF",
      "navbar_footer_frontyfornirowane": "Façades Plaquées",
      "navbar_footer_blatykompaktowe": "Plan de Travail Compacts",
      "navbar_footer_ciecieiokleinowanie": "Coupe et Placage (CNC)",
      "navbar_footer_frezowanie": "Fraisage",
      "navbar_footer_ostrzenienarzedzi": "Affûtage d'outils",
      "navbar_footer_stworzmy": "Créons ensemble",
      "navbar_footer_razemnowoczesne": "un intérieur moderne",
      "navbar_footer_wnetrza": "",
      "navbar_footer_adress": "Adresse:",
      "navbar_footer_contactfotter": "Contact:",

      "fronty_akrylowe_component_wysoki_polysk":"Façades Haute Brillance",
      "fronty_akrylowe_component_wysoki_polysk_zd1":
        `Fronty w wysokim połysku cieszą się niesłabnącą popularnością. 
        Główne zalety to niezwykła głębia koloru, efekt lustrzanego odbicia, 
        wysoka odporność na ścieranie i zarysowania.`,
      "fronty_akrylowe_component_wysoki_polysk_zd2":`
        Fronty połyskowe w wersji SR – czyli podwyższonej odporności na zarysowania, 
        dostępne są w ponad 20 kolorach, w tym 6 kolorach metalicznych.`,
      "fronty_akrylowe_component_wysoki_polysk_kolory":"Voir les couleurs disponibles: ",
      "fronty_akrylowe_component_wysoki_polysk_paleta":"Palette de Couleurs",
      
      "fronty_akrylowe_component_gleboki_mat":"Façades Tapis Profond",
      "fronty_akrylowe_component_geloboki_mat_zd1":`
        Głębokie matowe powierzchnie tworzą elegancką stonowane charakterystykę wnętrza. 
        Niemal jedwabista w dotyku struktura, niepalcujący się materiał, 
        wysoka odporność na ścieranie i zarysowanie, to tylko niektóre cechy produktu.`,
      "fronty_akrylowe_component_gleboki_mat_zd2":`
        Fronty matowe w wersji SR – czyli podwyższonej odporności na zarysowania, 
        dostępne są w ponad 12 kolorach, w tym 5 kolorach metalicznych.`,
      "fronty_akrylowe_component_gleboki_mat_kolory":"Voir les couleurs disponibles: ",
      "fronty_akrylowe_component_gleboki_mat_paleta":"Palette de Couleurs",
      
      "fronty_akrylowe_component_velvet":"Façades de la collection Velvet",
      "fronty_akrylowe_component_velvet_zd1":`
        Nigdy jeszcze powierzchnie płyt i frontów meblowych nie były tak 
        ultramatowe oraz aksamitne – ciepłe i przyjemne w dotyku.
        Zastosowana nanotechnologia powoduje, że powstałe na powierzchni 
        mikrozarysowania można usunąć w bardzo łatwy sposób.`,
      "fronty_akrylowe_component_velvet_zd2":
        `Fronty velvet, dostępne są w ponad 20 kolorach.`,
      "fronty_akrylowe_component_velvet_kolory":"Voir les couleurs disponibles: ",
      "fronty_akrylowe_component_velvet_paleta":"Palette de Couleurs",

      
      "fronty_akrylowe_polysk_kolor_frontywysokipolysk":"Façades Haute Brillance",
      "fronty_akrylowe_polysk_kolor_paletakolorow":"Palette de Couleurs",
      "fronty_akrylowe_polysk_kolor_bialy":"Blanc",
      "fronty_akrylowe_polysk_kolor_bialy_zimny":"Blanc Froid",
      "fronty_akrylowe_polysk_kolor_bez":"Beige",
      "fronty_akrylowe_polysk_kolor_jasny_szary":"Gris Clair",
      "fronty_akrylowe_polysk_kolor_jasny_cappucino":"Cappucino Brillant",
      "fronty_akrylowe_polysk_kolor_cappucino":"Cappucino",
      "fronty_akrylowe_polysk_kolor_golebi":"Pigeon",
      "fronty_akrylowe_polysk_kolor_jasnoniebieski":"Bleu Clair",
      "fronty_akrylowe_polysk_kolor_niebieski":"Bleu",
      "fronty_akrylowe_polysk_kolor_szary":"Gris",
      "fronty_akrylowe_polysk_kolor_ciemnoszary":"Gris Foncé",
      "fronty_akrylowe_polysk_kolor_czarny":"Noir",
      "fronty_akrylowe_polysk_kolor_biala_perla_metalik":"Blanc Nacré Métallique",
      "fronty_akrylowe_polysk_kolor_szary_srebrny_metalik":"Gris Argent Métallique",
      "fronty_akrylowe_polysk_kolor_bez_metalik":"Beige Métallique",
      "fronty_akrylowe_polysk_kolor_niebieski_metalik":"Bleu Métallique",
      "fronty_akrylowe_polysk_kolor_grafit_metalik":"Graphite Métallique",
      "fronty_akrylowe_polysk_kolor_czarny_metalik":"Noir Métallique",

      "fronty_akrylowe_mat_frontyakrylowe":"Façades Acryliques",
      "fronty_akrylowe_mat_glebokimat":"Façades Tapis Profond",
      "fronty_akrylowe_mat_paletakolorow":"Palette de Couleurs",
      "fronty_akrylowe_mat_bialymat":"Tapis Blanc",
      "fronty_akrylowe_mat_bezmat":"Tapis Beige",
      "fronty_akrylowe_mat_jasnyszarymat":"Tapis Gris Clair",
      "fronty_akrylowe_mat_capuccinomat":"Tapis Cappucino",
      "fronty_akrylowe_mat_granitmat":"Tapis de Granit",
      "fronty_akrylowe_mat_bezmetalikmat":"Tapis Beige Métallisé",
      "fronty_akrylowe_mat_szarymat":"Tapis Gris",
      "fronty_akrylowe_mat_grafitmetalikmat":"Tapis Graphite Métallique",
      "fronty_akrylowe_mat_granatmat":"Tapis Grenade",
      "fronty_akrylowe_mat_czarnymat":"Tapis Noir",

      "fronty_akrylowe_velvet_frontyakrylowe":"Façades Acryliques",
      "fronty_akrylowe_velvet_frontykolekcjivelvet":"Façades de la collection Velvet",
      "fronty_akrylowe_velvet_paletakolorow":"Palette de Couleurs",
      "fronty_akrylowe_velvet_nazamowienie":"commander",
      "fronty_akrylowe_velvet_bialyalpejski":"Biały Alpejski",
      "fronty_akrylowe_velvet_bialy":"Biały",
      "fronty_akrylowe_velvet_bialyporcelanowy":"Biały Porcelanowy",
      "fronty_akrylowe_velvet_jasnyszary":"Jasny Szary",
      "fronty_akrylowe_velvet_jasnybezowy":"Jasny Beżowy",
      "fronty_akrylowe_velvet_waniliowy":"Waniliowy",
      "fronty_akrylowe_velvet_ciemnybezowy":"Ciemny Beżowy",
      "fronty_akrylowe_velvet_popielaty":"Popielaty",
      "fronty_akrylowe_velvet_rozowy":"Różowy",
      "fronty_akrylowe_velvet_ceglasty":"Ceglasty",
      "fronty_akrylowe_velvet_szary":"Szary",
      "fronty_akrylowe_velvet_zielony":"Zielony",
      "fronty_akrylowe_velvet_niebieski":"Niebieski",
      "fronty_akrylowe_velvet_stalowy":"Stalowy",
      "fronty_akrylowe_velvet_bordowy":"Bordowy",
      "fronty_akrylowe_velvet_ciemnyzielony":"Ciemny Zielony",
      "fronty_akrylowe_velvet_grafitowy":"Grafitowy",
      "fronty_akrylowe_velvet_antracytowy":"Antracytowy",      
      "fronty_akrylowe_velvet_granatowy":"Granatowy",
      "fronty_akrylowe_velvet_brazowy":"Brązowy",
      "fronty_akrylowe_velvet_ciemnoniebieski":"Ciemno Niebieski",
      "fronty_akrylowe_velvet_czarny":"Czarny",

      "fronty_frezowane_mdf_frontyfrezowanemdf": "Façades Fraisées MDF",
      "fronty_frezowane_mdf_zd1": 
        `Lakierowane fronty sprawiają że nasza kuchnia staję się 
        naszą wizytówką. Ozdobione dodatkowo frezem mogą powodować 
        wrażenie mebli w stylu klasycznym dając poczucie luksusu lub
        nowoczesności nadając meblom trójwymiarowy wygląd.`,
      "fronty_frezowane_mdf_zd2": 
        `Jakość wymaga najlepszych rozwiązań.
        Stosujemy płyty MDF tylko do głębokiego frezowania.
        Fronty wykonywane są z płyt MDF 18 mm i 19 mm .`,
      "fronty_frezowane_mdf_zd3": 
        `Wybranie odpowiedniego frezowanego wzoru może być wyzwaniem.
        Dzięki nowoczesnemu sprzetowi i wykwalifikowanej, 
        profesjonalnej kadrze możemy wykonać: `,
      "fronty_frezowane_mdf_symetrycznelinie": "lignes symétriques",
      "fronty_frezowane_mdf_ostrekaty": "angles vifs",
      "fronty_frezowane_mdf_trojwymiaroweprojekty": "projets en trois dimensions",
      "fronty_frezowane_mdf_inne": "et autre",
      "fronty_frezowane_mdf_zd4": "Fronty mogą posiadać dowolny wzór odpowiadający",
      "fronty_frezowane_mdf_zd5": "Twojemu stylowi",

      "blaty_kompaktowe_blatykompaktowe": "Plan de Travail Compacts",
      "blaty_kompaktowe_zd1": 
        `Cienkie blaty kompaktowe to innowacja i propozycja dla osób szukających 
        oryginalnych rozwiązań na polskim rynku meblarskim. 
        Wyróżniają się nowoczesnym designem, są jednolite i nieporowate, 
        a przez to bardzo łatwe w utrzymaniu higieny. Jednorodne rdzeniem 
        dopasowane są kolorystycznie do powierzchni blatu.`,
      "blaty_kompaktowe_zd2": "Cienkie blaty HPL",
      "blaty_kompaktowe_zd3": 
        `przeznaczone są m.in. do kuchni oraz łazienek, 
        są wilgocioodporne i odporne na mikro-zarysowania.`,
      "blaty_kompaktowe_zobaczdostepnewzory": "Voir les couleurs disponibles:",
      "blaty_kompaktowe_paletawzornikow": "Palette de Couleurs",

      "blaty_kompaktowe_kolory_blatykompaktowe": "Plan de Travail Compacts",
      "blaty_kompaktowe_kolory_blatykompaktowewzorniki": "Plan de Travail Compacts Pochoirs",
      "blaty_kompaktowe_kolory_paletawzornikow": "Palette de Pochoirs",
      "blaty_kompaktowe_kolory_bialyglebokimat": "Biały Głęboki Mat",
      "blaty_kompaktowe_kolory_szaryglebodkimat": "Szary Głęboki Mat",
      "blaty_kompaktowe_kolory_oliwkowyglebokimat": "Oliwkowy Głęboki Mat",
      "blaty_kompaktowe_kolory_czarnyglebokimat": "Czarny Głęboki Mat",
      "blaty_kompaktowe_kolory_bialy": "Biały",
      "blaty_kompaktowe_kolory_dabbialy": "Dąb Biały",
      "blaty_kompaktowe_kolory_jasnyszary": "Jasny Szary",
      "blaty_kompaktowe_kolory_czarny": "Czarny",
      "blaty_kompaktowe_kolory_ciemnyszary": "Ciemny Szary",
      "blaty_kompaktowe_kolory_miedzianyszary": "Miedziany Szary",
      "blaty_kompaktowe_kolory_dabszary": "Dąb Szary",
      "blaty_kompaktowe_kolory_dabnaturalny": "Dąb Naturalny",
      "blaty_kompaktowe_kolory_marmur": "Marmur",
      "blaty_kompaktowe_kolory_bezowy": "Beżowy",
      "blaty_kompaktowe_kolory_szary": "Szary",
      "blaty_kompaktowe_kolory_agat": "Agat",
      "blaty_kompaktowe_kolory_brazowy": "Brązowy",
      "blaty_kompaktowe_kolory_granit": "Granit",

      "ciecie_okleinowanie_ciecieokleinowanie": "Coupe et Placage",
      "ciecie_okleinowanie_cennikuslug": "Liste de Prix des Services",
      "ciecie_okleinowanie_title1": "Usługa Cięcia i Okleinowania Płyt Meblowych",
      "ciecie_okleinowanie_ciecie": "Coupe",
      "ciecie_okleinowanie_od": "de",
      "ciecie_okleinowanie_okleinowanie": "Placage",
      "ciecie_okleinowanie_uslugacnc": "Service CNC",
      "ciecie_okleinowanie_za1minpracy": "pour 1 minute de travail",
      "ciecie_okleinowanie_nawiertypodpuszki": "Nawierty pod puszki Ø35 mm",
      "ciecie_okleinowanie_odpunktu": "du point",
      "ciecie_okleinowanie_title2": "Usługi Ostrzenia Narzędzi",
      "ciecie_okleinowanie_frezydiamentowe": "Frezy diamentowe",
      "ciecie_okleinowanie_na": "sur",
      "ciecie_okleinowanie_za1zab": "pour 1 dent",
      "ciecie_okleinowanie_glowicadookeiniarki": "Głowica do Okleiniarki",
      "ciecie_okleinowanie_pilydiamentowe": "Piły diamentowe",
      "ciecie_okleinowanie_podcinakidiamentowe": "Podcinaki diamentowe",
      "ciecie_okleinowanie_frezksztaltowymalyhss": "Frez kształtowy mały HSS",
      "ciecie_okleinowanie_frezksztaltowymalyhm": "Frez kształtowy mały HM",
      "ciecie_okleinowanie_wiertlopuszkowehss": "Wiertło puszkowe HSS",
      "ciecie_okleinowanie_wiertlopuszkowehm": "Wiertło puszkowe HM",
      "ciecie_okleinowanie_podcinak": "PODCINAK Ø120 24=Z",
      "ciecie_okleinowanie_piladofestol160": "Piła do (Festol) Ø160 48=Z",
      "ciecie_okleinowanie_piladoukosnicy25064": "Piła do ukośnicy Ø250 64=Z",
      "ciecie_okleinowanie_piladoukosnicy25072": "Piła do ukośnicy Ø250 72=Z",
      "ciecie_okleinowanie_pilapanelowa30096": "Piła panelowa Ø300 96=Z",
      "ciecie_okleinowanie_pilapanelowa350108": "Piła panelowa Ø350 108=Z",

      "contact_name": "Contact",
      "contact_message1": "Vous avez des questions sur nos produits?",
      "contact_skontaktujsieznamie": "Contactez-nous!",
      "contact_zapraszamy": "Nous invitons",
      "contact_biuro": "Bureau:",
      "contact_jestesmynawhatsapp": "Nous sommes sur WhatsApp",
      "contact_przedstawicielhandlowy": "Commercial:",

      "onas_onas": "À propos de nous",
      "onas_zd1": 
       `jesteśmy nowoczesną, ciągle rozwijającą się firmą, zdobywającą doświadczenie na zachodnich rynkach. 
       Specjalizujemy się w produkcji frontów meblowych na wymiar. W naszej ofercie znajdą Państwo najwyższej jakości fronty akrylowe, 
       laminowane oraz MDF, które stwarzają  nieograniczone możliwości aranżacji wnętrz. 
       Doskonale sprawdzą się w meblach kuchennych, pokojowych, łazienkowych, biurowych i wielu innych 
       pomieszczeniach  użytku codziennego. Dostosowując  się do trendów panujących  na rynku, dysponujemy szeroką gamą dekorów o nowoczesnym wyglądzie, 
       ciekawym wzornictwie i wysokiej jakości. Nasze produkty powstają dzięki zastosowaniu najwyższej klasy 
       materiałów i nowoczesnych technologii, dlatego do produkcji używamy wyłącznie artykułów  renomowanych producentów polskich jak i zagranicznych. 
       Każdy wytworzony przez nas produkt cechuje doskonała jakość, duża trwałość i łatwość utrzymania. Dzięki naszej różnorodnej ofercie, 
       każde wnętrze może prezentować się niepowtarzalnie i nowocześnie, a zarazem funkcjonalnie i ergonomicznie.
       Dzięki wieloletniemu doświadczeniu w pokrewnych branżach, mamy świadomość oczekiwań Klientów i staramy się im sprostać, 
       tym samym do każdego zamówienia podchodzimy indywidualnie. Współpracujemy z najlepszymi producentami w Polsce.`,
      "onas_zd2": 
        `Naszym sztandarowym produktem są fronty akrylowe , które ze względu na swoje właściwości i zastosowanie  znajdują uznanie wśród dużego grona Klientów. 
        Fronty akrylowe tworzone są podczas łączenia powłoki trwałego akrylu i płyty meblowej. Krawędzie frontu pokrywane są obrzeżem przy zastosowaniu najnowszej 
        technologii advantEdge, co zapewnia najwyższą jakość wykonania. Dzięki swojej budowie i właściwościach fronty akrylowe są bardzo dobrą alternatywą dla frontów lakierowanych. 
        Oprócz korzystnej ceny i dużej wytrzymałości na uszkodzenia, tego typu fronty umożliwiają wykonanie mebli o wysokiej estetyce. Decydując się na fronty akrylowe zyskujemy 
        wygląd i wysoką jakość produktu a zarazem komfort korzystania i łatwość utrzymania.`,
    }
  }