import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/services/language/language.service';
import * as Rellax from 'rellax';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    
  private lang : string = "PL";
  private Language: any = this._languageService.GetLanguageConfiguration();

  constructor(private _languageService: LanguageService) { }

  async ngOnInit() {
    var rellaxHeader = new Rellax('.rellax-header');

    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');

    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
        
    this.lang = await this._languageService.GetLanguageFromStrorage();
    
    this.Language = await this._languageService.GetLanguageConfiguration();   
  }
}
