import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/services/language/language.service';

@Component({
  selector: 'app-mat-paleta-kolorow',
  templateUrl: './mat-paleta-kolorow.component.html',
  styleUrls: ['./mat-paleta-kolorow.component.scss']
})
export class MatPaletaKolorowComponent implements OnInit {

  private Language: any = this._languageService.GetLanguageConfiguration();

  public mat_matrix = []

  constructor(private _languageService : LanguageService) { }

  async ngOnInit(){
    this.Language = await this._languageService.GetLanguageConfiguration();
    this.mat_matrix = [
      {'key': '1982', 'value': this.Language.fronty_akrylowe_mat_bialymat},
      {'key': '7496', 'value': this.Language.fronty_akrylowe_mat_bezmat},
      {'key': '85468', 'value': this.Language.fronty_akrylowe_mat_jasnyszarymat},
      {'key': '7498', 'value': this.Language.fronty_akrylowe_mat_capuccinomat},
      {'key': '85735', 'value': this.Language.fronty_akrylowe_mat_granitmat},
      {'key': '7591', 'value': this.Language.fronty_akrylowe_mat_bezmetalikmat},
      {'key': '85383', 'value': this.Language.fronty_akrylowe_mat_szarymat},
      {'key': '8855-85688', 'value': this.Language.fronty_akrylowe_mat_grafitmetalikmat},
      {'key': '4702', 'value': this.Language.fronty_akrylowe_mat_granatmat},
      {'key': '8421', 'value': this.Language.fronty_akrylowe_mat_czarnymat},
    ]
  }
}
