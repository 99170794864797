import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/services/language/language.service';

@Component({
  selector: 'app-velvet-paleta-kolorow',
  templateUrl: './velvet-paleta-kolorow.component.html',
  styleUrls: ['./velvet-paleta-kolorow.component.scss']
})
export class VelvetPaletaKolorowComponent implements OnInit {

  private Language: any = this._languageService.GetLanguageConfiguration();

  public velvet_matrix = []

  constructor(private _languageService : LanguageService) { }

  async ngOnInit() {
    this.Language = await this._languageService.GetLanguageConfiguration();
    this.velvet_matrix = [
      {'key': '1649', 'value': this.Language.fronty_akrylowe_velvet_bialyalpejski, 'new': true, 'on_demand':false},
      {'key': '1648', 'value': this.Language.fronty_akrylowe_velvet_bialy, 'new': true, 'on_demand':false},
      {'key': '1551', 'value': this.Language.fronty_akrylowe_velvet_bialyporcelanowy, 'new': true, 'on_demand':false},
      {'key': '7473', 'value': this.Language.fronty_akrylowe_velvet_jasnyszary, 'new': true, 'on_demand':false},
      {'key': '7393', 'value': this.Language.fronty_akrylowe_velvet_jasnybezowy, 'new': true, 'on_demand':false},
      {'key': '2156', 'value': this.Language.fronty_akrylowe_velvet_waniliowy, 'new': true, 'on_demand':false},
      {'key': '7361', 'value': this.Language.fronty_akrylowe_velvet_ciemnybezowy, 'new': true, 'on_demand':false},
      {'key': '7574', 'value': this.Language.fronty_akrylowe_velvet_popielaty, 'new': true, 'on_demand':false},
      {'key': '5987', 'value': this.Language.fronty_akrylowe_velvet_rozowy, 'new': true, 'on_demand':false},
      {'key': '5983', 'value': this.Language.fronty_akrylowe_velvet_ceglasty, 'new': true, 'on_demand':true},
      {'key': '7415', 'value': this.Language.fronty_akrylowe_velvet_szary, 'new': true, 'on_demand':false},
      {'key': '4246', 'value': this.Language.fronty_akrylowe_velvet_zielony, 'new': true, 'on_demand':false},
      {'key': '6220', 'value': this.Language.fronty_akrylowe_velvet_niebieski, 'new': true, 'on_demand':false},
      {'key': '7576', 'value': this.Language.fronty_akrylowe_velvet_stalowy, 'new': true, 'on_demand':false},
      {'key': '5989', 'value': this.Language.fronty_akrylowe_velvet_bordowy, 'new': true, 'on_demand':true},
      {'key': '4258', 'value': this.Language.fronty_akrylowe_velvet_ciemnyzielony, 'new': true, 'on_demand':false},
      {'key': '7394', 'value': this.Language.fronty_akrylowe_velvet_grafitowy, 'new': true, 'on_demand':false},
      {'key': '7462', 'value': this.Language.fronty_akrylowe_velvet_antracytowy, 'new': true, 'on_demand':false},
      {'key': '6221', 'value': this.Language.fronty_akrylowe_velvet_granatowy, 'new': true, 'on_demand':false},
      {'key': '7575', 'value': this.Language.fronty_akrylowe_velvet_brazowy, 'new': true, 'on_demand':true},
      {'key': '6230', 'value': this.Language.fronty_akrylowe_velvet_ciemnoniebieski, 'new': true, 'on_demand':true},
      {'key': '7322', 'value': this.Language.fronty_akrylowe_velvet_czarny, 'new': true}
    ]
  }
}
