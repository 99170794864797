import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { ComponentsComponent } from './components/components.component';
import { ContactComponent } from './components/contact/contact.component';
import { FrontyAkryloweComponent } from './components/fronty-akrylowe/fronty-akrylowe.component';
import { PolyskPaletaKolorowComponent } from './components/fronty-akrylowe/polysk-paleta-kolorow/polysk-paleta-kolorow.component';
import { MatPaletaKolorowComponent } from './components/fronty-akrylowe/mat-paleta-kolorow/mat-paleta-kolorow.component';
import { VelvetPaletaKolorowComponent } from './components/fronty-akrylowe/velvet-paleta-kolorow/velvet-paleta-kolorow.component';
import { NaszeRealizacjeComponent } from './components/nasze-realizacje/nasze-realizacje.component';
import { BlatyKompaktoweComponent } from './components/blaty-kompaktowe/blaty-kompaktowe.component';
import { BlatyPaletaKolorowComponent } from './components/blaty-kompaktowe/blaty-paleta-kolorow/blaty-paleta-kolorow.component';
import { FrontyFrezowaneMdfComponent } from './components/fronty-frezowane-mdf/fronty-frezowane-mdf.component';
import { ONasComponent } from './components/o-nas/o-nas.component';
import { CiecieOkreinowanieComponent } from './components/ciecie-okreinowanie/ciecie-okreinowanie.component';
import { FrontyFornirowaneComponent } from './components/fronty-fornirowane/fronty-fornirowane.component';

const routes: Routes =[
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: ComponentsComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'o-nas', component: ONasComponent },
    { path: 'fronty-akrylowe', component: FrontyAkryloweComponent },
    { path: 'fronty-fornirowane', component: FrontyFornirowaneComponent },
    { path: 'polysk-paleta-kolorow', component: PolyskPaletaKolorowComponent },
    { path: 'mat-paleta-kolorow', component: MatPaletaKolorowComponent },
    { path: 'velvet-paleta-kolorow', component: VelvetPaletaKolorowComponent },
    { path: 'nasze-realizacje', component: NaszeRealizacjeComponent },
    { path: 'blaty-kompaktowe', component: BlatyKompaktoweComponent },
    { path: 'blaty-paleta-kolorow', component: BlatyPaletaKolorowComponent },
    { path: 'fronty-frezowane-mdf', component: FrontyFrezowaneMdfComponent },
    { path: 'ciecie-okleinowanie', component: CiecieOkreinowanieComponent },
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes, { useHash: true })
    ],
    exports: [
    ],
})
export class AppRoutingModule { }
