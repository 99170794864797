import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public Version = "v1.3.0"
  public ReleaseDate = "2021-11-11 22:00";

  constructor() { }
}
