import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-full-screen-image-display',
  templateUrl: './full-screen-image-display.component.html',
  styleUrls: ['./full-screen-image-display.component.scss']
})
export class FullScreenImageDisplayComponent implements OnInit {

  public Images: string [] = [];

  constructor(private activeModal: NgbActiveModal) { }

  async ngOnInit() {
    
  }

  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

}
