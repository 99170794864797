import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/services/language/language.service';

@Component({
  selector: 'app-blaty-kompaktowe',
  templateUrl: './blaty-kompaktowe.component.html',
  styleUrls: ['./blaty-kompaktowe.component.scss']
})
export class BlatyKompaktoweComponent implements OnInit {

  private Language: any = this._languageService.GetLanguageConfiguration();

  constructor(private _languageService : LanguageService) { }

  async ngOnInit() {
    this.Language = await this._languageService.GetLanguageConfiguration();  
  }
}
