import { Component, OnInit } from '@angular/core';
import * as Rellax from 'rellax';
import { LanguageService } from 'app/services/language/language.service';

@Component({
  selector: 'app-o-nas',
  templateUrl: './o-nas.component.html',
  styleUrls: ['./o-nas.component.scss']
})
export class ONasComponent implements OnInit {

  private Language: any = this._languageService.GetLanguageConfiguration();

  constructor(private _languageService: LanguageService) { }

  async ngOnInit() {
    var rellaxHeader = new Rellax('.rellax-header');

    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');

    var navbar = document.getElementsByTagName('nav')[0];
    navbar.classList.add('navbar-transparent');
        
    
    this.Language = await this._languageService.GetLanguageConfiguration();   
  }
}
