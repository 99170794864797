import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AkrylandImage } from 'app/components/nasze-realizacje/nasze-realizacje.component';
import { FullScreenImageDisplayComponent } from '../full-screen-image-display/full-screen-image-display.component';

@Injectable({
  providedIn: 'root'
})
export class FullScreenImageDisplayService {

  constructor(private modalService: NgbModal) { }

  public confirm(images: AkrylandImage[], dialogSize: 'sm'|'lg' = 'lg'): Promise<boolean> {
    const modalRef = this.modalService.open(FullScreenImageDisplayComponent, {windowClass: 'full-screen-modal', backdrop: false});
    modalRef.componentInstance.Images = images;

    return modalRef.result;
  }
}
