import { Component, OnInit } from '@angular/core';
import { FullScreenImageDisplayService } from 'app/modals/full-screen-image-display/full-screen-image-display.service';
import { LanguageService } from 'app/services/language/language.service';

@Component({
  selector: 'app-nasze-realizacje',
  templateUrl: './nasze-realizacje.component.html',
  styleUrls: ['./nasze-realizacje.component.scss']
})
export class NaszeRealizacjeComponent implements OnInit {

  private Language: any = this._languageService.GetLanguageConfiguration();

  public Images : AkrylandImage [] = [
    { Name: 'photo01.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo02.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo03.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo04.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo05.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo06.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo07.jpg', DescriptionUp: 'Fronty Akryland: płyta Velvet matt, dekor 5987.', DescriptionDown: 'Ralizacja: Studio Kuchni F.P.H.U. LOBELA'},
    { Name: 'photo08.jpg', DescriptionUp: 'Fronty Akryland: płyta Velvet matt, dekor 5987.', DescriptionDown: 'Ralizacja: Studio Kuchni F.P.H.U. LOBELA'},
    { Name: 'photo09.jpg', DescriptionUp: 'Fronty Akryland: płyta Velvet matt, dekor 5987.', DescriptionDown: 'Ralizacja: Studio Kuchni F.P.H.U. LOBELA'},
    { Name: 'photo10.jpg', DescriptionUp: 'Fronty Akryland: płyta Velvet matt, dekor 5987.', DescriptionDown: 'Ralizacja: Studio Kuchni F.P.H.U. LOBELA'},
    { Name: 'photo11.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo12.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo13.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo14.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo15.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo16.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo17.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo18.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo19.jpg', DescriptionUp: '', DescriptionDown: ''},
    { Name: 'photo20.jpg', DescriptionUp: '', DescriptionDown: ''}
  ];

  constructor(private _fullScreenImageDisplayService: FullScreenImageDisplayService,
    private _languageService : LanguageService) { }

  async ngOnInit() {
    this.Language = await this._languageService.GetLanguageConfiguration();   
  }

  private RunModal(){
    this._fullScreenImageDisplayService.confirm(this.Images)
      .then(async(confirmed) => {
          
      })
      .catch(() => {
          
      });
  }
}

export class AkrylandImage {
  public Name: string;
  public DescriptionUp: string;
  public DescriptionDown: string;
}
