export class Pol {
    public static Configuration = {
      "navbar_footer_aboutus": "O Firmie",
      "navbar_footer_oursolutions": "Nasze Realizacje",
      "navbar_footer_contaktnav": "Kontakt",
      "navbar_footer_produkcja_frontow": "Produkcja Frontów",
      "navbar_footer_fronty_akrylowe": "Fronty Akrylowe",
      "navbar_footer_fronty_frezowane": "Fronty Frezowane MDF",
      "navbar_footer_fronty_fornirowane": "Fronty Fornirowane",
      "navbar_footer_uslugi_cnc": "Usługi CNC",
      "navbar_footer_uslugi_ciecieokleinowanie": "Cięcie i okleinowanie",
      "navbar_footer_uslugi_frezowanie": "Frezowanie",
      "navbar_footer_blaty_kompaktowe": "Blaty Kompaktowe",
      "navbar_footer_motto1": "Z pasji do tworzenia",
      "navbar_footer_motto2": "dobrego",
      "navbar_footer_motto3": "designu",
      "navbar_footer_zapraszamydokontaktu": "Zapraszamy do kontaktu:",
      "navbar_footer_jestesmynawhatsapp": "Jesteśmy na WhatsApp",
      "navbar_footer_przedstawicielhandlowy": "Przedstawiciel Handlowy:",
      "navbar_footer_jako": "Jako",
      "navbar_footer_jedyni": "jedyni",
      "navbar_footer_na_podkarpaciu": "na podkarpaciu wykonujemy fronty akrylowe w najnowocześniejszej technologii z użyciem",
      "navbar_footer_obrzeza_laserowego": "obrzeża laserowego.",
      "navbar_footer_onas_nadbanerem": 
        `jesteśmy nowoczesną, ciągle rozwijającą się firmą, zdobywającą doświadczenie na zachodnich rynkach. 
        Specjalizujemy się w produkcji frontów meblowych na wymiar. W naszej ofercie znajdą Państwo najwyższej jakości fronty akrylowe, 
        laminowane oraz MDF.`,
      "navbar_footer_ourportfolio": "Nasze Portfolio:",
      "navbar_footer_frontyakrylowe": "Fronty Akrylowe",
      "navbar_footer_frontyfrezowane": "Fronty Frezowane MDF",
      "navbar_footer_frontyfornirowane": "Fronty Fornirowane",
      "navbar_footer_blatykompaktowe": "Blaty Kompaktowe",
      "navbar_footer_ciecieiokleinowanie": "Cięcie i Okleinowanie (CNC)",
      "navbar_footer_frezowanie": "Frezowanie",
      "navbar_footer_ostrzenienarzedzi": "Ostrzenie Narzędzi",
      "navbar_footer_stworzmy": "Stwórzmy",
      "navbar_footer_razemnowoczesne": "razem nowoczesne",
      "navbar_footer_wnetrza": "wnętrza",
      "navbar_footer_adress": "Adres:",
      "navbar_footer_contactfotter": "Kontakt:",

      "fronty_akrylowe_component_wysoki_polysk":"Fronty Wysoki Połysk",
      "fronty_akrylowe_component_wysoki_polysk_zd1":
        `Fronty w wysokim połysku z w pełni utwardzonego akrylu 1800 cieszą się niesłabnącą popularnością. 
        Główne zalety to niezwykła głębia koloru, efekt lustrzanego odbicia, 
        wysoka odporność na ścieranie i zarysowania.`,
      "fronty_akrylowe_component_wysoki_polysk_zd2":`
        Fronty połyskowe w wersji SR – czyli podwyższonej odporności na zarysowania, 
        dostępne są w ponad 20 kolorach, w tym 6 kolorach metalicznych.`,
      "fronty_akrylowe_component_wysoki_polysk_kolory":"Zobacz dostępne kolory: ",
      "fronty_akrylowe_component_wysoki_polysk_paleta":"Paleta Kolorów",
      
      "fronty_akrylowe_component_gleboki_mat":"Fronty Głęboki Mat",
      "fronty_akrylowe_component_geloboki_mat_zd1":`
        Głębokie matowe powierzchnie z w pełni utwardzonego akrylu 1800 tworzą elegancką stonowane charakterystykę wnętrza. 
        Niemal jedwabista w dotyku struktura, niepalcujący się materiał, 
        wysoka odporność na ścieranie i zarysowanie, to tylko niektóre cechy produktu.`,
      "fronty_akrylowe_component_gleboki_mat_zd2":`
        Fronty matowe w wersji SR – czyli podwyższonej odporności na zarysowania, 
        dostępne są w ponad 12 kolorach, w tym 5 kolorach metalicznych.`,
      "fronty_akrylowe_component_gleboki_mat_kolory":"Zobacz dostępne kolory: ",
      "fronty_akrylowe_component_gleboki_mat_paleta":"Paleta Kolorów",
      
      "fronty_akrylowe_component_velvet":"Fronty z Kolekcji Velvet",
      "fronty_akrylowe_component_velvet_zd1":`
        Nigdy jeszcze powierzchnie płyt i frontów meblowych nie były tak 
        ultramatowe oraz aksamitne – ciepłe i przyjemne w dotyku.
        Zastosowana nanotechnologia powoduje, że powstałe na powierzchni 
        mikrozarysowania można usunąć w bardzo łatwy sposób.`,
      "fronty_akrylowe_component_velvet_zd2":
       `Fronty velvet, dostępne są w ponad 20 kolorach.`,
      "fronty_akrylowe_component_velvet_kolory":"Zobacz dostępne kolory: ",
      "fronty_akrylowe_component_velvet_paleta":"Paleta Kolorów",
      
      "fronty_akrylowe_polysk_kolor_frontywysokipolysk":"Fronty Wysoki Połysk",
      "fronty_akrylowe_polysk_kolor_paletakolorow":"Paleta Kolorów",
      "fronty_akrylowe_polysk_kolor_bialy":"Biały",
      "fronty_akrylowe_polysk_kolor_bialy_zimny":"Biały Zimny",
      "fronty_akrylowe_polysk_kolor_bez":"Beż",
      "fronty_akrylowe_polysk_kolor_jasny_szary":"Jasny Szary",
      "fronty_akrylowe_polysk_kolor_jasny_cappucino":"Jasny Cappucino",
      "fronty_akrylowe_polysk_kolor_cappucino":"Cappucino",
      "fronty_akrylowe_polysk_kolor_golebi":"Gołębi",
      "fronty_akrylowe_polysk_kolor_jasnoniebieski":"Jasnoniebieski",
      "fronty_akrylowe_polysk_kolor_niebieski":"Niebieski",
      "fronty_akrylowe_polysk_kolor_szary":"Szary",
      "fronty_akrylowe_polysk_kolor_ciemnoszary":"Ciemnoszary",
      "fronty_akrylowe_polysk_kolor_czarny":"Czarny",
      "fronty_akrylowe_polysk_kolor_biala_perla_metalik":"Biała Perła Metalik",
      "fronty_akrylowe_polysk_kolor_szary_srebrny_metalik":"Szary-Srebrny Metalik",
      "fronty_akrylowe_polysk_kolor_bez_metalik":"Beż Metalik",
      "fronty_akrylowe_polysk_kolor_niebieski_metalik":"Niebieski Metalik",
      "fronty_akrylowe_polysk_kolor_grafit_metalik":"Grafit Metalik",
      "fronty_akrylowe_polysk_kolor_czarny_metalik":"Czarny Metalik",

      "fronty_akrylowe_mat_frontyakrylowe":"Fronty Akrylowe",
      "fronty_akrylowe_mat_glebokimat":"Fronty Głęboki Mat",
      "fronty_akrylowe_mat_paletakolorow":"Paleta Kolorów",
      "fronty_akrylowe_mat_bialymat":"Biały Mat",
      "fronty_akrylowe_mat_bezmat":"Beż Mat",
      "fronty_akrylowe_mat_jasnyszarymat":"Jasny Szary Mat",
      "fronty_akrylowe_mat_capuccinomat":"Cappucino Mat",
      "fronty_akrylowe_mat_granitmat":"Granit Mat",
      "fronty_akrylowe_mat_bezmetalikmat":"Beż Metalik Mat",
      "fronty_akrylowe_mat_szarymat":"Szary Mat",
      "fronty_akrylowe_mat_grafitmetalikmat":"Grafit Metalik Mat",
      "fronty_akrylowe_mat_granatmat":"Granat Mat",
      "fronty_akrylowe_mat_czarnymat":"Czarny Mat",

      "fronty_akrylowe_velvet_frontyakrylowe":"Fronty Akrylowe",
      "fronty_akrylowe_velvet_frontykolekcjivelvet":"Fronty Kolekcja Velvet",
      "fronty_akrylowe_velvet_paletakolorow":"Paleta Kolorów",
      "fronty_akrylowe_velvet_nazamowienie":"na zamówienie",
      "fronty_akrylowe_velvet_bialyalpejski":"Biały Alpejski",
      "fronty_akrylowe_velvet_bialy":"Biały",
      "fronty_akrylowe_velvet_bialyporcelanowy":"Biały Porcelanowy",
      "fronty_akrylowe_velvet_jasnyszary":"Jasny Szary",
      "fronty_akrylowe_velvet_jasnybezowy":"Jasny Beżowy",
      "fronty_akrylowe_velvet_waniliowy":"Waniliowy",
      "fronty_akrylowe_velvet_ciemnybezowy":"Ciemny Beżowy",
      "fronty_akrylowe_velvet_popielaty":"Popielaty",
      "fronty_akrylowe_velvet_rozowy":"Różowy",
      "fronty_akrylowe_velvet_ceglasty":"Ceglasty",
      "fronty_akrylowe_velvet_szary":"Szary",
      "fronty_akrylowe_velvet_zielony":"Zielony",
      "fronty_akrylowe_velvet_niebieski":"Niebieski",
      "fronty_akrylowe_velvet_stalowy":"Stalowy",
      "fronty_akrylowe_velvet_bordowy":"Bordowy",
      "fronty_akrylowe_velvet_ciemnyzielony":"Ciemny Zielony",
      "fronty_akrylowe_velvet_grafitowy":"Grafitowy",
      "fronty_akrylowe_velvet_antracytowy":"Antracytowy",      
      "fronty_akrylowe_velvet_granatowy":"Granatowy",
      "fronty_akrylowe_velvet_brazowy":"Brązowy",
      "fronty_akrylowe_velvet_ciemnoniebieski":"Ciemnoniebieski",
      "fronty_akrylowe_velvet_czarny":"Czarny",

      "fronty_frezowane_mdf_frontyfrezowanemdf": "Fronty Frezowane MDF",
      "fronty_frezowane_mdf_zd1": 
        `Lakierowane fronty sprawiają że nasza kuchnia staję się 
        naszą wizytówką. Ozdobione dodatkowo frezem mogą powodować 
        wrażenie mebli w stylu klasycznym dając poczucie luksusu lub
        nowoczesności nadając meblom trójwymiarowy wygląd.`,
      "fronty_frezowane_mdf_zd2": 
        `Jakość wymaga najlepszych rozwiązań.
        Stosujemy płyty MDF tylko do głębokiego frezowania.
        Fronty wykonywane są z płyt MDF 18 mm i 19 mm .`,
      "fronty_frezowane_mdf_zd3": 
        `Wybranie odpowiedniego frezowanego wzoru może być wyzwaniem.
        Dzięki nowoczesnemu sprzetowi i wykwalifikowanej, 
        profesjonalnej kadrze możemy wykonać: `,
      "fronty_frezowane_mdf_symetrycznelinie": "symetryczne linie",
      "fronty_frezowane_mdf_ostrekaty": "ostre kąty",
      "fronty_frezowane_mdf_trojwymiaroweprojekty": "trójwymiarowe projekty",
      "fronty_frezowane_mdf_inne": "i inne",
      "fronty_frezowane_mdf_zd4": "Fronty mogą posiadać dowolny wzór odpowiadający",
      "fronty_frezowane_mdf_zd5": "Twojemu stylowi",

      "blaty_kompaktowe_blatykompaktowe": "Blaty Kompaktowe",
      "blaty_kompaktowe_zd1": 
        `Cienkie blaty kompaktowe to innowacja i propozycja dla osób szukających 
        oryginalnych rozwiązań na polskim rynku meblarskim. 
        Wyróżniają się nowoczesnym designem, są jednolite i nieporowate, 
        a przez to bardzo łatwe w utrzymaniu higieny. Jednorodne rdzeniem 
        dopasowane są kolorystycznie do powierzchni blatu.`,
      "blaty_kompaktowe_zd2": "Cienkie blaty HPL",
      "blaty_kompaktowe_zd3": 
        `przeznaczone są m.in. do kuchni oraz łazienek, 
        są wilgocioodporne i odporne na mikro-zarysowania.`,
      "blaty_kompaktowe_zobaczdostepnewzory": "Zobacz dostępne wzory:",
      "blaty_kompaktowe_paletawzornikow": "Paleta Wzorników",

      "blaty_kompaktowe_kolory_blatykompaktowe": "Blaty Kompaktowe",
      "blaty_kompaktowe_kolory_blatykompaktowewzorniki": "Blaty Kompaktowe Wzorniki",
      "blaty_kompaktowe_kolory_paletawzornikow": "Paleta Wzorników",
      "blaty_kompaktowe_kolory_bialyglebokimat": "Biały Głęboki Mat",
      "blaty_kompaktowe_kolory_szaryglebodkimat": "Szary Głęboki Mat",
      "blaty_kompaktowe_kolory_oliwkowyglebokimat": "Oliwkowy Głęboki Mat",
      "blaty_kompaktowe_kolory_czarnyglebokimat": "Czarny Głęboki Mat",
      "blaty_kompaktowe_kolory_bialy": "Biały",
      "blaty_kompaktowe_kolory_dabbialy": "Dąb Biały",
      "blaty_kompaktowe_kolory_jasnyszary": "Jasny Szary",
      "blaty_kompaktowe_kolory_czarny": "Czarny",
      "blaty_kompaktowe_kolory_ciemnyszary": "Ciemny Szary",
      "blaty_kompaktowe_kolory_miedzianyszary": "Miedziany Szary",
      "blaty_kompaktowe_kolory_dabszary": "Dąb Szary",
      "blaty_kompaktowe_kolory_dabnaturalny": "Dąb Naturalny",
      "blaty_kompaktowe_kolory_marmur": "Marmur",
      "blaty_kompaktowe_kolory_bezowy": "Beżowy",
      "blaty_kompaktowe_kolory_szary": "Szary",
      "blaty_kompaktowe_kolory_agat": "Agat",
      "blaty_kompaktowe_kolory_brazowy": "Brązowy",
      "blaty_kompaktowe_kolory_granit": "Granit",

      "ciecie_okleinowanie_ciecieokleinowanie": "Cięcie i Okleinowanie",
      "ciecie_okleinowanie_cennikuslug": "Cennik Usług",
      "ciecie_okleinowanie_title1": "Usługa Cięcia i Okleinowania Płyt Meblowych",
      "ciecie_okleinowanie_ciecie": "Cięcie",
      "ciecie_okleinowanie_od": "od",
      "ciecie_okleinowanie_okleinowanie": "Okleinowanie",
      "ciecie_okleinowanie_uslugacnc": "Usługa CNC",
      "ciecie_okleinowanie_za1minpracy": "za 1 min pracy",
      "ciecie_okleinowanie_nawiertypodpuszki": "Nawierty pod puszki Ø35 mm",
      "ciecie_okleinowanie_odpunktu": "od punktu",
      "ciecie_okleinowanie_title2": "Usługi Ostrzenia Narzędzi",
      "ciecie_okleinowanie_frezydiamentowe": "Frezy diamentowe",
      "ciecie_okleinowanie_na": "na",
      "ciecie_okleinowanie_za1zab": "za 1 ząb",
      "ciecie_okleinowanie_glowicadookeiniarki": "Głowica do Okleiniarki",
      "ciecie_okleinowanie_pilydiamentowe": "Piły diamentowe",
      "ciecie_okleinowanie_podcinakidiamentowe": "Podcinaki diamentowe",
      "ciecie_okleinowanie_frezksztaltowymalyhss": "Frez kształtowy mały HSS",
      "ciecie_okleinowanie_frezksztaltowymalyhm": "Frez kształtowy mały HM",
      "ciecie_okleinowanie_wiertlopuszkowehss": "Wiertło puszkowe HSS",
      "ciecie_okleinowanie_wiertlopuszkowehm": "Wiertło puszkowe HM",
      "ciecie_okleinowanie_podcinak": "PODCINAK Ø120 24=Z",
      "ciecie_okleinowanie_piladofestol160": "Piła do (Festol) Ø160 48=Z",
      "ciecie_okleinowanie_piladoukosnicy25064": "Piła do ukośnicy Ø250 64=Z",
      "ciecie_okleinowanie_piladoukosnicy25072": "Piła do ukośnicy Ø250 72=Z",
      "ciecie_okleinowanie_pilapanelowa30096": "Piła panelowa Ø300 96=Z",
      "ciecie_okleinowanie_pilapanelowa350108": "Piła panelowa Ø350 108=Z",

      "contact_name": "Kontakt",
      "contact_message1": "Masz pytania odnośnie naszych produktów?",
      "contact_skontaktujsieznamie": "Skontaktuj się z nami!",
      "contact_zapraszamy": "Zapraszamy",
      "contact_biuro": "Biuro:",
      "contact_jestesmynawhatsapp": "Jesteśmy na WhatsApp",
      "contact_przedstawicielhandlowy": "Przedstawiciel Handlowy:",

      "onas_onas": "O nas",
      "onas_zd1": 
       `jesteśmy nowoczesną, ciągle rozwijającą się firmą, zdobywającą doświadczenie na zachodnich rynkach. 
       Specjalizujemy się w produkcji frontów meblowych na wymiar. W naszej ofercie znajdą Państwo najwyższej jakości fronty akrylowe, 
       laminowane oraz MDF, które stwarzają  nieograniczone możliwości aranżacji wnętrz. 
       Doskonale sprawdzą się w meblach kuchennych, pokojowych, łazienkowych, biurowych i wielu innych 
       pomieszczeniach  użytku codziennego. Dostosowując  się do trendów panujących  na rynku, dysponujemy szeroką gamą dekorów o nowoczesnym wyglądzie, 
       ciekawym wzornictwie i wysokiej jakości. Nasze produkty powstają dzięki zastosowaniu najwyższej klasy 
       materiałów i nowoczesnych technologii, dlatego do produkcji używamy wyłącznie artykułów  renomowanych producentów polskich jak i zagranicznych. 
       Każdy wytworzony przez nas produkt cechuje doskonała jakość, duża trwałość i łatwość utrzymania. Dzięki naszej różnorodnej ofercie, 
       każde wnętrze może prezentować się niepowtarzalnie i nowocześnie, a zarazem funkcjonalnie i ergonomicznie.
       Dzięki wieloletniemu doświadczeniu w pokrewnych branżach, mamy świadomość oczekiwań Klientów i staramy się im sprostać, 
       tym samym do każdego zamówienia podchodzimy indywidualnie. Współpracujemy z najlepszymi producentami w Polsce.`,
      "onas_zd2": 
        `Naszym sztandarowym produktem są fronty akrylowe , które ze względu na swoje właściwości i zastosowanie  znajdują uznanie wśród dużego grona Klientów. 
        Fronty akrylowe tworzone są podczas łączenia powłoki trwałego akrylu i płyty meblowej. Krawędzie frontu pokrywane są obrzeżem przy zastosowaniu najnowszej 
        technologii advantEdge, co zapewnia najwyższą jakość wykonania. Dzięki swojej budowie i właściwościach fronty akrylowe są bardzo dobrą alternatywą dla frontów lakierowanych. 
        Oprócz korzystnej ceny i dużej wytrzymałości na uszkodzenia, tego typu fronty umożliwiają wykonanie mebli o wysokiej estetyce. Decydując się na fronty akrylowe zyskujemy 
        wygląd i wysoką jakość produktu a zarazem komfort korzystania i łatwość utrzymania.`,
    }
  }