import { Component, OnInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { LanguageService } from "../../services/language/language.service";

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    
    private toggleButton: any;
    private sidebarVisible: boolean;
    
    public languageCode: string = 'PL';
    private Language: any = this._languageService.GetLanguageConfiguration();

    constructor(public location: Location, 
        private element : ElementRef,
        private _languageService : LanguageService) {
        this.sidebarVisible = false;
    }

    async ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

        this.languageCode = await this._languageService.GetLanguageFromStrorage();
        this.Language = await this._languageService.GetLanguageConfiguration();   
    }

    async SetLanguage(language: string){
        await this._languageService.SetLanguageToStorage(language);
        window.location.reload();
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };
    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };
  
    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if( titlee === '/documentation' ) {
            return true;
        }
        else {
            return false;
        }
    }
}
