import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'app/services/language/language.service';

@Component({
  selector: 'app-fronty-frezowane-mdf',
  templateUrl: './fronty-frezowane-mdf.component.html',
  styleUrls: ['./fronty-frezowane-mdf.component.scss']
})
export class FrontyFrezowaneMdfComponent implements OnInit {

  private Language: any = this._languageService.GetLanguageConfiguration();
  
  constructor(private _languageService : LanguageService) { }

  async ngOnInit() {
    this.Language = await this._languageService.GetLanguageConfiguration();
  }
}
