import { Component, OnInit } from '@angular/core';
import { LanguageService} from 'app/services/language/language.service';

@Component({
  selector: 'app-fronty-akrylowe',
  templateUrl: './fronty-akrylowe.component.html',
  styleUrls: ['./fronty-akrylowe.component.scss']
})
export class FrontyAkryloweComponent implements OnInit {
  private Language: any = this._languageService.GetLanguageConfiguration();
  
  constructor(private _languageService : LanguageService) { }

  async ngOnInit() {
    this.Language = await this._languageService.GetLanguageConfiguration();
  }
}
